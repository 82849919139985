.single {
  display: flex;
  width: 100%;
  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        position: relative;

        .editButtonu {
          position: absolute;
          top: 0;
          right: 0;
          padding: 10px;
          font-size: 18px;
          color: #0e9e69;
          background-color: #7551f818;
          cursor: pointer;
          border-radius: 0px 0px 0px 5px;

          Link {
            text-decoration: none;
          }
        }

        .item {
          display: flex;
          gap: 20px;

          .itemImg {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }

          .details {
            .itemTitle {
              margin-bottom: 10px;
              color: #555;
            }

            .detailItem {
              margin-bottom: 10px;
              font-size: 14px;

              .itemKeyu {
                font-weight: bold;
                color: #0e9e69;
                margin-right: 15px;
              }

              .itemValue {
                font-weight: 300;
              }
            }
          }
        }
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;

      .btnDiv {
        border-bottom: 1px solid rgb(204, 204, 204);

        button {
          padding: 1rem;
          border: 1px solid rgba(197, 195, 195, 0.8);
          border-bottom: 0;
          border-radius: 5px;
          background-color: #fff;
          margin: 0;
          cursor: pointer;

          .slct {
            color: #0e9e69;
          }

          .idl {
            color: gray;
          }
        }
      }
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
  }
}
