.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    font-size: 3.5vw;
    font-weight: bold;
    color: #6439ff;
  }

  .item {
    position: absolute;
    top: 1rem;
    right: 2rem;
  }

  .signin {
    display: grid;
    align-items: center;
    background-color: rgb(252, 252, 252);
    width: 30%;
    padding: 2rem;
    border-radius: 1rem;

    .logoContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      img.logo {
        width: 120px;
        justify-self: center;
      }
    }

    h2 {
      justify-self: center;
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 30px;
      justify-content: center;
      align-items: center;

      .formInput {
        width: 80%;

        label {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1rem;
          font-weight: 400;
          color: #f05532;

          .icon {
            cursor: pointer;
          }
        }

        input {
          width: 100%;
          font-size: large;
          padding: 1rem;
          border: none;
          outline: 0;
          border-bottom: 1px solid gray;
        }
      }

      button {
        width: 20vw;
        padding: 1rem;
        border: none;
        background-color: #0e9e69;
        color: white;
        font-weight: 400;
        font-size: large;
        cursor: pointer;
        margin-top: 0.7rem;
      }
    }
  }
}
