.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modalContainer {
  width: 40%;
  padding: 2rem 0;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;

  h1 {
    color: #9e1d00;
  }
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;

  label {
    display: block;
    align-self: flex-start;
  }

  input {
    padding: 1rem;
    border: 1px solid #ccc;
    outline: none;
    margin-bottom: 1rem;
    width: 80%;
  }

  button.modalSubmitBtn {
    width: 80%;
    padding: 1rem;
    font-weight: 400;
    color: #fff;
    background-color: #0e9e69;
    border: 0;
    border-radius: 0.5rem;
    align-self: center;
    margin-bottom: 2rem;
  }
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

.suggestions {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #d4d4d4;
  background-color: #f7f7f7;
  border-radius: 0 0 10px 10px;
}

.suggestions li {
  list-style: none;
  padding: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  text-align: left;
}

.suggestion li:hover {
  background-color: rgba(245, 76, 39, 0.8);
}
