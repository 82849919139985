.pages {
  display: flex;
  width: 100%;

  .pagesContainer {
    flex: 6;
    padding: 20px;

    .title {
      font-size: 22px;
      color: #333;
    }

    .formContainer {
      background: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;

      form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        input,
        textarea,
        select {
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }

        button {
          background-color: #28a745;
          color: white;
          padding: 10px;
          border: none;
          cursor: pointer;
          border-radius: 5px;
        }
      }
    }

    .tableContainer {
      background: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

      table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
          padding: 10px;
          border-bottom: 1px solid #ddd;
        }

        .viewButton {
          text-decoration: none;
          background-color: #007bff;
          color: white;
          padding: 5px 10px;
          border-radius: 5px;
          margin-left: 5px;
        }
      }
    }
  }
}
