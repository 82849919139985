.pageView {
  display: flex;
  width: 100%;

  .pageViewContainer {
    flex: 6;
    padding: 20px;
    background-color: #fff;
    min-height: 100vh;

    .pageContentss {
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      margin: auto;

      h1 {
        font-size: 28px;
        margin-bottom: 20px;
        color: #333;
        text-align: center;
      }

      p {
        font-size: 16px;
        color: #555;
        line-height: 1.6;
      }

      img {
        max-width: 100%;
        height: auto;
        border-radius: 4px;
        margin-top: 10px;
      }

      a {
        color: #007bff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
