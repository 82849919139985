.container {
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 7rem;
    margin: 1rem;
    color: #6439ff;
  }

  h2 {
    font-size: 3rem;
    margin: 1rem;
    color: #858182;
  }

  p {
    font-size: 1.5rem;
    color: #de381f;
  }

  .link {
    text-decoration: none;
    color: green;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid green;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
  }
}
