.featured {
  flex: 2;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: gray;

    .title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .bottom {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .featuredChart {
      width: 40%;
      height: 100px;
      display: flex;
      justify-content: space-evenly;

      .rd {
        color: red;
      }
    }

    .title {
      font-weight: 500;
      color: gray;

      span.cad {
        justify-content: space-around;
        align-items: center;

        span.indicator {
          background-color: #0e9e69;
          border-radius: 0.3rem;
          border: 1px solid gray;
          margin-right: 0.5rem;
          padding: 0.3rem;
        }
        span.indicator1 {
          background-color: #9e1d00;
          border-radius: 0.3rem;
          border: 0.5px solid gray;
          margin-right: 0.5rem;
          margin-left: 0.5rem;
          padding: 0.1rem 0.3rem;
        }
      }
    }

    .amount {
      font-size: 30px;
    }

    .desc {
      font-weight: 300;
      font-size: 12px;
      color: gray;
      text-align: center;
    }

    .summary {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        text-align: center;

        .itemTitle {
          font-size: 14px;
          color: gray;
        }

        .itemResult {
          display: flex;
          align-items: center;
          margin-top: 10px;
          font-size: 14px;

          &.positive {
            color: green;
          }

          &.negative {
            color: red;
          }
        }
      }
    }
  }
}
