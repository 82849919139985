h1.maps {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: var(--first-color);
  z-index: 10;
  margin: 0;
  padding: 0;
}

.search_map {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}

.search_map input {
  padding: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
}

.locate {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  z-index: 10;
}
.locate img {
  width: 30px;
  cursor: pointer;
}

.mbl-link {
  display: none;
}

.backlink {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 5px;
  display: inline-block;
  color: var(--button-color);
}

@media only screen and (max-width: 760px) {
  .search {
    width: 80%;
  }

  .search input {
    padding: 0.4rem;
    font-size: 1rem;
  }

  .locate {
    right: 0.5rem;
    top: 1.5rem;
  }

  .locate img {
    width: 25px;
  }

  h1.maps {
    display: none;
  }

  .mbl-link {
    display: block;
    position: absolute;
    font-weight: 600;
    color: var(--button-color);
    z-index: 10;
    font-size: 1.8rem;
    left: 0.5rem;
    top: 1rem;
  }

  .backlink {
    display: none;
  }
}
