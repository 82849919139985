.datatable {
  height: 100%;
  padding: 20px;

  .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .tableHeader {
    font-weight: 600;
    color: teal;
  }

  th {
    font-weight: 600;
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }
    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }

    .unsubscribeButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: rgb(148, 148, 7);
      border: 1px dotted rgba(148, 148, 20, 0.596);
      cursor: pointer;
    }

    .subscribeButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: green;
      border: 1px dotted rgba(0, 148, 20, 0.596);
      cursor: pointer;
    }

    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }
  .topBar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      padding: 0.5rem;

      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 12px;
        }
      }
    }
    @media screen and (max-width: 480px) {
      display: grid;
      gap: 1rem;

      .search {
        justify-self: flex-end;
      }
    }
  }
}
